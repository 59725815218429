import React from 'react';
import Navbar from "../components/Navbar";
import Footer from '../components/Footer';
import '../css/TermsAndPrivacyPolicy.css';

const TermsAndPrivacyPolicy = () => {
  return (
    <>
    <Navbar />
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <div className="terms-privacy-container">
      <div className="terms-section">
        <h1><strong>Terms of Use</strong></h1>
        {/* <p><strong>Last Updated: [Date]</strong></p> */}

        <h2>1. Acceptance of Terms</h2>
        <p>By accessing and using AlQaimWelfareFoundation Website, you agree to comply with and be bound by these Terms of Use. If you do not agree to these terms, you may not use the Website.</p>

        <h2>2. Modifications to Terms</h2>
        <p>We reserve the right to modify these Terms of Use at any time without prior notice. Any changes will be effective upon posting to the Website. Continued use of the Website after modifications signifies your acceptance of the revised Terms.</p>

        <h2>3. Use of the Website</h2>
        <p>You agree to use the Website only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the Website.</p>

        <h2>4. Intellectual Property</h2>
        <p>All content on the Website, including text, graphics, logos, images, and software, is the property of AlQaimWelfareFoundation and is protected by copyright, trademark, and other laws. Unauthorized use of any content may violate these laws.</p>

        <h2>5. Disclaimer of Warranties</h2>
        <p>The Website is provided on an “as is” and “as available” basis. AlQaimWelfareFoundation makes no warranties, express or implied, regarding the accuracy or reliability of any content on the Website.</p>

        <h2>6. Limitation of Liability</h2>
        <p>AlQaimWelfareFoundation shall not be liable for any damages resulting from the use or inability to use the Website, including but not limited to indirect, incidental, or consequential damages.</p>

        <h2>7. Termination</h2>
        <p>We reserve the right to terminate or suspend access to the Website without prior notice or liability for any reason.</p>

        <h2>8. Governing Law</h2>
        <p>These Terms of Use are governed by the laws of Jurisdiction. Any disputes arising from the use of the Website shall be resolved in the courts of [Jurisdiction].</p>
      </div>

      <div className="privacy-section">
        <h1><strong>Privacy Policy</strong></h1>
        {/* <p><strong>Last Updated: [Date]</strong></p> */}

        <h2>1. Information We Collect</h2>
        <p><strong>Personal Information:</strong> We may collect personal information such as your name, email address, and contact information when you voluntarily provide it to us.</p>
        <p><strong>Usage Data:</strong> We collect data on how you access and use the Website, including your IP address, browser type, and pages visited.</p>

        <h2>2. How We Use Your Information</h2>
        <p>We may use the information collected for various purposes, including:</p>
        <ul>
          <li>To provide, operate, and maintain the Website</li>
          <li>To improve and personalize your experience on the Website</li>
          <li>To respond to your comments, questions, and requests</li>
          <li>To communicate with you about updates or promotional offers</li>
        </ul>

        <h2>3. Sharing of Information</h2>
        <p>We do not share your personal information with third parties except:</p>
        <ul>
          <li>With service providers who assist in operating the Website</li>
          <li>To comply with legal obligations</li>
          <li>To protect and defend our rights and property</li>
        </ul>

        <h2>4. Cookies and Tracking Technologies</h2>
        <p>Our Website may use cookies and similar tracking technologies to enhance your experience. You can adjust your browser settings to reject cookies, but this may affect the functionality of the Website.</p>

        <h2>5. Security of Your Information</h2>
        <p>We use administrative, technical, and physical security measures to protect your personal information. However, no method of transmission over the internet is 100% secure.</p>

        <h2>6. Third-Party Links</h2>
        <p>Our Website may contain links to other websites not operated by us. We are not responsible for the content or privacy practices of these websites.</p>

        <h2>7. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy periodically. Changes will be posted on this page with an updated effective date.</p>

        <h2>8. Contact Us</h2>
        <p>If you have questions about this Privacy Policy, please contact us at alqaimwelfare.</p>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default TermsAndPrivacyPolicy;
