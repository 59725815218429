// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDatfHnAqmCL49vHJVua_wtxrA27G8D4sg",
  authDomain: "alqaimwelfare-foundation.firebaseapp.com",
  databaseURL: "https://alqaimwelfare-foundation-default-rtdb.firebaseio.com",
  projectId: "alqaimwelfare-foundation",
  storageBucket: "alqaimwelfare-foundation.appspot.com",
  messagingSenderId: "954400279985",
  appId: "1:954400279985:web:c12429cf63a31e2fccc190",
  measurementId: "G-WKGKWTT26Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database };
