import Navbar from "../components/Navbar";
import '../css/admin.css';
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";

function Admin() {
    const [selectedMail, setSelectedMail] = useState(null);
    const [data, setData] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const [isEnabled, setIsEnabled] = useState(false);
    const [logs, setLogs] = useState([]);
    const [donations, setDonations] = useState([]);
    const [allDonations, setAllDonations] = useState([]);
    const [auth, setAuth] = useState("");
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        const user = localStorage.getItem("ADMIN_NGO");
        if (user) {
            setAuth(user);
            fetchDonations();  // fetchDonations is now only for demonstration since API is removed
        } else {
            adminLogin();
            setAllDonations([]);
        }
    }, []);

    const adminLogin = async () => {
        MySwal.fire({
            title: "ADMIN LOGIN",
            html:
                '<div class="form-floating mb-3">' +
                '<input type="email" id="floatingInput" class="form-control" placeholder="Admin Email" autocomplete="email">' +
                '<label for="floatingInput">ADMIN Email</label>' +
                '</div>' +
                '<div class="form-floating">' +
                '<input type="password" id="floatingPassword" class="form-control" placeholder="Your Password">' +
                '<label for="floatingPassword">Enter Password</label>' +
                '</div>',
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonText: "LogIn",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                const email = document.getElementById("floatingInput").value;
                const password = document.getElementById("floatingPassword").value;
                // Hardcoded authentication check
                if (email === "admin@gmail.com" && password === "admin123") {
                    localStorage.setItem("ADMIN_NGO", btoa(`${email}:${password}`));
                    localStorage.removeItem("NGO");
                    return true;
                } else {
                    MySwal.showValidationMessage("Invalid email or password");
                    return false;
                }
            },
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                MySwal.fire({
                    title: "ADMIN Logged In Successfully!",
                    icon: "success"
                }).then(() => { window.location.reload(); });
            } else if (result.dismiss === MySwal.DismissReason.cancel) {
                navigate("/");
            }
        });
    };

    // Dummy fetchDonations function to maintain structure without API call
    const fetchDonations = () => {
        // Sample data structure
        setAllDonations([
            { _id: "donor1@gmail.com", orderIds: ["O123", "O124"], amounts: [100, 200], totalAmount: 300 },
            { _id: "donor2@gmail.com", orderIds: ["O125"], amounts: [150], totalAmount: 150 },
        ]);
    };

    return (
        <>
            <Navbar />
            <div className="admin">
                <div className="container">
                    <h2 className="text-center text-light text-uppercase font-monospace fw-bold">ADMIN</h2>
                    {/* Rest of the component code remains unchanged */}
                </div>
            </div>
        </>
    )
}

export default Admin;
