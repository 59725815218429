import '../css/donate.css';
import qrCodeImage from '../assets/Initiatives/1aa18b40-10af-4cd8-8fae-517770154446.jpeg';
import Navbar from "../components/Navbar";
import Footer from '../components/Footer';
import BackToTop from "../components/BackToTop";
import PageHeader from '../components/PageHeader';
import { useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { database } from '../firebase';
import { ref, set } from 'firebase/database';

export default function Donate() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [amount, setAmount] = useState("");
    const [donationPurpose, setDonationPurpose] = useState("Khums");
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const MySwal = withReactContent(Swal);

    const saveDonationDetails = async (paymentStatus) => {
        const dateInIST = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));
        const currentDate = dateInIST.toISOString().replace(/T/, '_').replace(/\..+/, '');

        try {
            await set(ref(database, 'donations/' + currentDate), {
                name,
                email,
                mobileNumber,
                amount,
                donationPurpose,
                transactionTime: dateInIST.toISOString(),
                paymentStatus: paymentStatus || "pending"
            });
        } catch (error) {
            console.error("Error saving donation: ", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!agreeToTerms) {
            MySwal.fire({
                title: "Agreement Required",
                text: "You must agree to the terms and conditions",
                icon: "warning",
            });
            return;
        }

        const nameRegex = /^[A-Za-z\s]+$/;
        if (!nameRegex.test(name)) {
            MySwal.fire({
                title: "Invalid Name",
                text: "Please enter a valid name (letters and spaces only).",
                icon: "warning",
            });
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            MySwal.fire({
                title: "Invalid Email",
                text: "Please enter a valid email address.",
                icon: "warning",
            });
            return;
        }

        const mobileRegex = /^\d{10}$/;
        if (!mobileRegex.test(mobileNumber)) {
            MySwal.fire({
                title: "Invalid Mobile Number",
                text: "Please enter a valid 10-digit mobile number.",
                icon: "warning",
            });
            return;
        }

        if (isNaN(amount) || Number(amount) <= 0) {
            MySwal.fire({
                title: "Invalid Amount",
                text: "Please enter a valid donation amount",
                icon: "warning",
            });
            return;
        }

        await saveDonationDetails("pending");

        // Display QR Code with charity quote and download button
        MySwal.fire({
            title: '<h3>Charity begins at home, but it doesn’t have to end there.</h3>',
            html: `
                <div>
                    <img 
                        src="${qrCodeImage}" 
                        alt="QR Code for donation" 
                        style="width: 300px; height: 300px; margin-bottom: 15px;" 
                    />
                    <a 
                        href="${qrCodeImage}" 
                        download="Donation_QR_Code.jpeg"
                        style="
                            text-decoration: none;
                            padding: 10px 20px; 
                            background-color: #fdbe33; 
                            color: white; 
                            font-size: 16px; 
                            border: none; 
                            border-radius: 5px; 
                            cursor: pointer;"
                    >
                        Download QR
                    </a>
                </div>
            `,
            showCloseButton: true,
            showConfirmButton: false,
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: true,
        });
    };

    const resetForm = () => {
        setName("");
        setEmail("");
        setMobileNumber("");
        setAmount("");
        setDonationPurpose("Khums");
        setAgreeToTerms(false);
    };

    return (
        <>
            <Navbar />
            <PageHeader title={"Donate Now"} />
            <div className="donations">
                <div className="container">
                    <div className="donate">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="donate-content">
                                    <div className="section-header">
                                        <p>Donate Now</p>
                                        <h2>Let's donate to needy people for better lives</h2>
                                    </div>
                                    <div className="donate-text">
                                        <p><h3 style={{color:'green'}}>Rasule Khuda (saww)</h3>
                                            se puchha gaya ke kaun sa Sadqa behtar hai? Aap (saww) ne farmaya, 
                                            "Dushman rishte-daar ko Sadqa dena behtar hai". 
                                            Sawaabul Aamaal, p305
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="donate-form">
                                    <form onSubmit={handleSubmit}>
                                        <div className="control-group">
                                            <input
                                                onChange={(e) => setName(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                placeholder="Full Name"
                                                required
                                            />
                                        </div>
                                        <div className="control-group">
                                            <input 
                                                onChange={(e) => setEmail(e.target.value)} 
                                                type="email" 
                                                className="form-control" 
                                                placeholder="Email" 
                                                required 
                                            />
                                        </div>
                                        <div className="control-group">
                                            <input 
                                                onChange={(e) => setMobileNumber(e.target.value)} 
                                                type="tel" 
                                                className="form-control" 
                                                placeholder="Mobile Number" 
                                                required 
                                            />
                                        </div>
                                        <div className="control-group">
                                            <input 
                                                onChange={(e) => setAmount(e.target.value)} 
                                                type="number" 
                                                className="form-control" 
                                                placeholder="Enter donation amount" 
                                                required 
                                                min="1"
                                            />
                                        </div>

                                        <div className="control-group">
                                            <select
                                                className="form-control"
                                                value={donationPurpose}
                                                onChange={(e) => setDonationPurpose(e.target.value)}
                                                required
                                            >
                                                <option value="Khums">Mustahab Sadaqah</option>
                                                <option value="Sadaqah">Wajib Sadaqah</option>
                                                <option value="Donation">Donation</option>
                                                <option value="Sahm-E-Sadaat">Sahm-E-Sadaat</option>
                                                <option value="Sahm-E-Imam">Sahm-E-Imam</option>
                                                <option value="Others">Others</option>
                                            </select>
                                        </div>

                                        <div className="control-group">
                                            <a href="/TermsAndPrivacyPolicy" target="_blank"><i className="fa fa-angle-right"></i> Terms and Conditions</a><br></br>
                                            <input
                                                type="checkbox"
                                                onChange={(e) => setAgreeToTerms(e.target.checked)}
                                                required
                                            />
                                            <label>&nbsp; I accept the terms and conditions</label>
                                        </div>
                                        
                                        <div>
                                            <button className="btn btn-custom" type="submit" style={{ borderRadius: "12px" }}>
                                                Donate Now
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <BackToTop />
        </>
    );
}
