// src/components/knowmore.jsx

import React from 'react';
import Navbar from "../components/Navbar";
import Footer from '../components/Footer';
import '../css/knowmore.css';
import placeholderImage from '../assets/alqaim.jpeg'; // Replace with your own images later
import placeholderImage1 from '../assets/Initiatives/062dd88e-f874-4627-ba73-6db3594787db.jpeg';
import placeholderImage2 from '../assets/Initiatives/charity.jpeg';

export default function KnowMore() {
  return (
    <>
    {/* <div style={}>ABOUT US</div> */}
    <Navbar />
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <div className="knowmore-container">

    <section className="welfare-section">
        <img src={placeholderImage} alt="Al Qaim Welfare" className="welfare-image" />
        <div className="welfare-text">
          <h2>AL QAIM WELFARE FOUNDATION</h2>
          <p>
            Al Qaim Welfare is A Non Profit Organization Whose work is to Help needy people without any Returns. 
            The Main Purpose Of This group is to seek nearness to Allah By Helping his Prophet's Muhammad (s.a.w.w.s) 
            Ummah. Al Qaim Welfare Is a NPO Set Up Mainly To Provide Service To Ummah Without discriminating. 
            They Extend help free of cost. It is established with the primary motive of collecting and distributing 
            Sadaqah to those in need and Follow The sunnah Of Prophet (s.a.w.w.s) And His Household i.e., Ahlebayt (a.s).
          </p>
        </div>
      </section>

      <section className="help-section">
      <img src={placeholderImage1} alt="Helping Needy" className="help-image" />
        <div className="help-text">
          <h2>How To Help The Needy?</h2>
          <p>
            The Best Way to provide support to the poor and needy people is to give SADQA (Charity). 
            Sadaqah is a term used in Islamic tradition to refer to the voluntary giving of alms or charity. 
            Sadaqah helps to purify one's wealth and remove any impurities or imperfections. When a person 
            gives charity, it is recorded for him as a continuous act of goodness until the Day of Judgment. 
            Sadaqah should be given with the intention of seeking Allah's pleasure and earning rewards. It is 
            considered one of the most important acts of worship in Islam and is mentioned in the Quran and 
            Hadith as a means of seeking Allah's pleasure and earning rewards.
          </p>
        </div>
       
      </section>

      <section className="introduction-section">
        <img src={placeholderImage2} alt="Equality" className="intro-image" />
        <div className="intro-text">
          {/* <h1>Introduction</h1> */}
          <p>
            All Humans are Equal In the sight of God. Every Human Is Honored And Dignified.
            The Acts like discrimination, usurping, inequality Made poor more poorer and rich more richer. 
            God has made the poor and needy a trust for the believers, and that they should be treated with 
            justice and fairness. God Advocates For Social Justice and the Establishment of Systems that 
            ensure the rights of the needy people are protected. This includes fair economic practices and 
            equitable distribution of resources.
          </p>
        </div>
      </section>
    </div>
    <Footer />
    </>
  );
}
