import '../css/footer.css';

export default function Footer() {
    return (
        < div className="footer" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-contact">
                            <h2>Contact Us</h2>
                            <a href="" target="_blank" rel="noopener noreferrer"><p><i className="fa fa-map-marker-alt"></i>Dongri, Mumbai, India</p></a>
                            <a href="tel:+91-7738805577"><p><i className="fa fa-phone"></i>+91-7738805577</p></a>
                            <a href="mailto:alqaimwelfareinfo@gmail.com"><p><i className="fa fa-envelope"></i>alqaimwelfareinfo@gmail.com</p></a>
                            <div className="footer-social">
                                {/* <a className="btn btn-custom" href="https://twitter.com"><i className="fab fa-x-twitter"></i></a> */}
                                <a className="btn btn-custom" href="https://www.facebook.com/profile.php?id=61567726026527&mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-custom" href="https://www.instagram.com/al.qaim_welfare/profilecard/?igsh=OXpjZ3Y3bTExbnE3"><i className="fab fa-instagram"></i></a>
                                {/* <a className="btn btn-custom" href="https://www.youtube.com"><i className="fab fa-youtube"></i></a> */}
                                <a className="btn btn-custom" href="https://www.threads.net/@al.qaim_welfare?invite=0"><i className="fab fa-threads"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-link">
                            <h2>Popular Links</h2>
                            <a href="/KnowMore">About Us</a>
                            <a href="/contact">Contact Us</a>
                            <a href="/event">Sermons</a>
                            <a href="/Donate">Donate</a>
                            {/* <a href="/event">Upcoming Events</a>
                            <a href="#">Latest Blog</a> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-link">
                            <h2>Useful Links</h2>
                            <a href="/TermsAndPrivacyPolicy">Terms of use &  Privacy Policy</a>
                            {/* <a href="/TermsAndPrivacyPolicy">Privacy policy</a> */}
                            {/* <a href="#">Cookies</a> */}
                            <a href="/contact">Help</a>
                            {/* <a href="#">FQAs</a> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        {/* <div className="footer-newsletter">
                            <h2>Newsletter</h2>
                            <form name='NewsLetter'>
                                <input name='subscribe' className="form-control" placeholder="Email goes here" autoComplete='email' required/>
                                <button className="btn btn-custom">Submit</button>
                                <h6>Don't worry, we don't spam!</h6>
                            </form>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="container copyright">
                <div className="row">
                    <div className="col-md-6">
                        <p>&copy; <a href="#">Al Qaim Welfare Foundation</a> | 2024, All Right Reserved.</p>
                    </div>
                    <div className="col-md-6">
                        <p>Designed By Ali with Love</p>
                    </div>
                </div>
            </div>
        </ div>
    );
}