// src/components/Landing.jsx

import React from 'react';
import '../css/landing.css';
import main from '../assets/alqaim.jpeg';
import { useNavigate } from 'react-router-dom';

export default function Landing() {
  const navigate = useNavigate();

  return (
    <div className="landing">
      <span className="left">
        <div className="caption1">
          <hr />
          {/* <h4>WE ARE Al Qaim Welfare Foundation</h4> */}
        </div>
        <div className="titles">
          <div className="caption2">
            <p>At <span>Al Qaim Welfare Foundation,</span> we work under the guidance of <span> Aalim-e-deen </span></p>
          </div>
          <div className="caption3">
            <p><h4>Rasule Khuda (saww) :-</h4>
          Sadqa is hadd tak do ke kaha jaaye ke tumne israaf kiya (fuzool-kharchi ki), jab ke wo israaf na hoga.
          Furoo e Kafi, v3, p124</p>
          </div>
          <div className="btns">
            <button onClick={() => navigate("/knowmore")} type="button" className="btn btn-outline-info">Know More</button>
            <button type="button" className="btn btn-success" onClick={() => navigate("/donate")}>DONATE</button>
          </div>
        </div>
      </span>
      <span className="right">
        <img src={main} alt="Smiling Faces" />
      </span>
    </div>
  );
}
